
import { Vue, PropSync, Component } from "vue-property-decorator";
import LoginForm from "@/components/forms/LoginForm.vue";
import StepTracker from "@/components/request/StepTracker.vue";

@Component({
  components: {
    LoginForm,
    StepTracker
  }
})
export default class DialogIdentify extends Vue {
  @PropSync("dialog", { type: Boolean }) syncedDialog!: boolean;

  private url_frontoffice = process.env.VUE_APP_FRONT_OFFICE_URL as string;

  private mounted() {
    if (this.$store.state.logged === true) {
      this.userIdentification("log");
    }
  }
  private userIdentification(method: string) {
    this.syncedDialog = false;
    this.$emit("identify", method);
  }

  private routerGo(name: any) {
    if (name && name != "") {
      if (name == "newrequest") {
        window.open(this.url_frontoffice, "_self");
      } else if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          this.$q.notify({
            message: "Ha ocurrido un error",
            color: "principal",
            position: "top",
            timeout: 5000
          });
        });
      }
    }
  }
}
