
import { Component, Prop, Mixins, Watch } from "vue-property-decorator";
import FormValidator from "@/mixins/FormValidator";
import DateHelpers from "@/mixins/DateHelpers";
import LabelHelpers from "@/mixins/LabelHelpers";
import comunas from "@/helpers/comunas.json";
import countries from "@/helpers/countries.json";

interface guestData {
  name: string;
  father_last_name: string;
  mother_last_name: string;
  nationality: any;
  document_type: string;
  document_id: string | null;
  motive: any | null;
  district: any;
  street: string;
  street_number: string;
  hint: string;
  email: string;
  phone: string;
  date_birth: string | null;
  genre: string | number;
}

interface Tipos {
  value: number | any;
  label: string;
}

@Component
export default class GuestForm extends Mixins(
  FormValidator,
  DateHelpers,
  LabelHelpers
) {
  @Prop({ default: false }) readonly date_request!: boolean;
  options_document: object[] = [
    { label: "DNI", value: "0" },
    { label: "RUT", value: "1" },
    { label: "Pasaporte", value: "2" }
  ];
  options_motive: object[] = [];

  options_district: any = comunas;
  options_nationality: any = countries;
  options_street: any = [];
  streets: any[] = [];
  street_loading = false;
  street_search_empty = false;
  confirm_email = false;
  chilean = true;
  comuna_municipalidad: any = {
    id: 0,
    id_region: 0,
    nombre: ""
  };

  protected formData: guestData = {
    name: "",
    father_last_name: "",
    mother_last_name: "",
    nationality: {
      value: 47,
      code: "CL",
      country_en: "Chile",
      country_es: "Chile",
      label: "Chilena"
    },
    motive: null,
    document_type: "1",
    document_id: "",
    district: "",
    street: "",
    street_number: "",
    hint: "",
    email: "",
    phone: "",
    date_birth: "",
    genre: ""
  };

  options_genre = [
    { label: "Femenino", value: "1" },
    { label: "Masculino", value: "2" },
    { label: "Otro", value: "3" },
    { label: "Prefiero no decirlo", value: "4" },
    { label: "Sin información", value: "5" }
  ];

  private aMotivo: Array<any> = [];
  private motivoData: Tipos | null = null;

  private fechaHoy: any = new Date();
  private formattedFecha: string | null = "";

  private reject_tarjetavecino: boolean = false;

  // private motivoData: Tipos = {
  //   value: null,
  //   label: "Selecciona una opción",
  // };

  my_locale = {
    days: "Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado".split("_"),
    daysShort: "Dom_Lun_Mar_Mié_Jue_Vie_Sáb".split("_"),
    months: "Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre".split(
      "_"
    ),
    monthsShort: "Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic".split("_"),
    firstDayOfWeek: 7
  };

  @Watch("motivoData")
  private optionMotivo(newValue: any) {
    this.formData.motive = newValue.value;
  }

  @Watch("formData.phone")
  private watchPhone(newValue: any) {
    let formated = newValue.replace(" ", "");
    formated = formated.replace(" ", "");
    formated = formated.replace(".", "");
    formated = formated.replace("-", "");
    formated = formated.replace("+", "");
    formated = formated.replace(",", "");
    this.formData.phone = formated;
  }

  private highlightMotive() {
    window.scrollTo(0, 0);
    //(this.$refs.guestForm as any).validate();
  }

  private clearStreet(): void {
    if (this.formData.district.label == this.comuna_municipalidad.nombre) {
      this.formData.street = "";
    }
  }

  private limitSince(date: any) {
    return Date.parse(date as any) <= Date.parse(this.fechaHoy as any);
  }

  private loadStoredData(): void {
    if (this.isUserLogged) {
      this.formData.name = this.$store.state.user.name;
      this.formData.father_last_name = this.$store.state.user.father_last_name;
      this.formData.mother_last_name = this.$store.state.user.mother_last_name;
      this.formData.nationality = countries.find(
        country => country.value === this.$store.state.user.country
      );
      this.formData.document_type = this.$store.state.user.document_type.toString();
      this.formData.document_id = this.$store.state.user.rut;
      this.formData.district = comunas.find(
        district => district.label === this.$store.state.user.adress.district
      );
      this.formData.street = this.$store.state.user.adress.street;
      this.formData.street_number = this.$store.state.user.adress.number;
      this.formData.hint = this.$store.state.user.adress.hint;
      this.formData.email = this.$store.state.user.email;
      this.formData.phone = this.$store.state.user.phone;
      if (this.$store.state.user.date_birth != null) {
        this.formattedFecha = this.correctDate(
          this.$store.state.user.date_birth
        );
      }
      this.formData.genre = this.$store.state.user.genre.toString();
      if (this.$store.state.ticket.motive !== "") {
        for (let i = 0; i < this.aMotivo.length; i++) {
          if (this.aMotivo[i].value == this.$store.state.ticket.motive) {
            this.motivoData = {
              value: this.aMotivo[i].value,
              label: this.aMotivo[i].label
            };
          }
        }
      } /* else {
        this.$store.state.ticket.motive = "1";
      }*/
    } else if (this.$store.state.ticket.guest) {
      this.formData.name = this.$store.state.ticket.guest_name;
      this.formData.father_last_name = this.$store.state.ticket.guest_father_name;
      this.formData.mother_last_name = this.$store.state.ticket.guest_mother_name;
      this.formData.nationality = countries.find(
        country => country.value === this.$store.state.ticket.guest_country
      );
      this.formData.document_type = this.$store.state.ticket.guest_document_type.toString();
      this.formData.document_id = this.$store.state.ticket.guest_id;
      this.$store.state.ticket.guest_adress_district;
      this.formData.district = comunas.find(
        district =>
          district.label === this.$store.state.ticket.guest_adress_district
      );
      this.formData.street = this.$store.state.ticket.guest_adress_street;
      this.formData.street_number = this.$store.state.ticket.guest_adress_number;
      this.formData.hint = this.$store.state.ticket.guest_adress_hint;
      this.formData.email = this.$store.state.ticket.guest_email;
      this.formData.phone = this.$store.state.ticket.guest_phone;
      if (this.$store.state.ticket.guest_date_birth != null) {
        this.formattedFecha = this.$store.state.ticket.guest_date_birth;
      }
      this.formData.genre = this.$store.state.ticket.guest_genre.toString();
      if (this.$store.state.ticket.motive !== "") {
        for (let i = 0; i < this.aMotivo.length; i++) {
          if (this.aMotivo[i].value == this.$store.state.ticket.motive) {
            this.motivoData = {
              value: this.aMotivo[i].value,
              label: this.aMotivo[i].label
            };
          }
        }
      }
    }
  }

  validateTarjetaVecino() {
    this.$axios.get("servicios/tarjetaVecino", {
      params: {
        rut: this.formData.document_id,
        id_servicio: this.$store.state.ticket.service.id
      }
    }).then(response => {
      if(response.data != "NOT_NEEDED" && response.data[0] != "VIGENTE"){
        this.reject_tarjetavecino = true;
      } else {
        this.confirm_email = true;
      }
    }).catch(response => {
      this.$q.notify({
        message: "Hubo un problema leyendo los datos",
        color: "principal",
        position: "top",
        timeout: 5000
      });
    })
  }

  private confirmDialog(): void {
    this.validateTarjetaVecino();
  }



  goToHome() {
    this.$store.dispatch("setStateToCero");
    window.location.href = process.env.VUE_APP_FRONT_OFFICE_URL as string
  }

  goToTarjetaVecino() {
    window.open('https://nunoa.tarjetavecino.com/home', '_blank')
  }

  private identifyAttemp(): void {
    if (this.formData.motive == "" && this.date_request != true) {
      this.$q.notify({
        message: "Debe seleccionar un Motivo de contacto",
        color: "principal",
        position: "top",
        timeout: 5000
      });
    } else {
      if (this.formattedFecha != "" && this.formattedFecha != null) {
        const { formattedFecha } = this;
        if (!/^\d{2}-\d{2}-\d{4}$/.test(formattedFecha)) {
          this.$q.notify({
            message:
              "Fecha incompleta. Por favor, ingrese una fecha en el formato dd-mm-aaaa",
            color: "principal",
            position: "top",
            timeout: 5000
          });
          return;
        } else if (!this.checkDate(formattedFecha)) {
          this.$q.notify({
            message: "Fecha inválida. Por favor, escriba una fecha válida",
            color: "principal",
            position: "top",
            timeout: 5000
          });
          return;
        } else if (
          Date.parse(this.fechaHoy as any) <
          Date.parse(this.birthDate(formattedFecha))
        ) {
          this.$q.notify({
            message: "La fecha de nacimiento debe ser menor a la fecha actual",
            color: "principal",
            position: "top",
            timeout: 5000
          });
          return;
        }
      } else {
        this.formattedFecha = null;
      }
      let ticket_data: any = {};
      ticket_data.motive = this.formData.motive;
      ticket_data.step = 3;
      if (!this.$store.state.logged) {
        this.$axios
          .post("personas/identificar", {
            nombre: this.formData.name,
            apellido_paterno: this.formData.father_last_name,
            apellido_materno: this.formData.mother_last_name,
            email: this.formData.email,
            telefono: this.formData.phone,
            tipo_documento: Number(this.formData.document_type),
            rut: this.formData.document_id,
            id_pais: this.formData.nationality.value,
            fecha_nacimiento: this.formattedFecha,
            genero: Number(this.formData.genre),
            //borrar una vez que se arregle el endpoint
            // kkkk
            tipo: 1,
            direccion: {
              id_comuna: this.formData.district.value,
              calle: this.formData.street,
              numero: this.formData.street_number,
              aclaratoria: this.formData.hint
            }
            // solicitud: this.date_request
          })
          .then(response => {
            let payload = {
              ticket: ticket_data,
              guest: response.data
            };
            this.$store.dispatch("submitRequestStepTwo", payload);
          })
          .catch(error => {
            switch (error.response.data.error.message) {
              case "duplicate_rut":
                this.$router.push({ name: 'login', params: { redirect: 'newrequest' } });
                this.$q.notify({
                  message: "El rut ya está registrado. Para realizar una solicitud debe Iniciar Sesión.",
                  color: "principal",
                  position: "top",
                  timeout: 5000
                });
                break;
              case "duplicate_email_error":
                this.$q.notify({
                  message: "El correo ya se encuentra registrado",
                  color: "principal",
                  position: "top",
                  timeout: 5000
                });
                break;
              case "duplicate_email":
                this.$q.notify({
                  message: "El correo ya se encuentra registrado",
                  color: "principal",
                  position: "top",
                  timeout: 5000
                });
                break;
              case "auth/email-already-exists":
                this.$q.notify({
                  message: "El correo ya se encuentra registrado",
                  color: "principal",
                  position: "top",
                  timeout: 5000
                });
                break;
              default:
                this.$q.notify({
                  message: "Ha ocurrido un error",
                  color: "principal",
                  position: "top",
                  timeout: 5000
                });
                break;
            }
          });
      } else {
        let payload = {
          ticket: ticket_data
        };
        this.$store.dispatch("submitRequestStepTwo", payload);
      }
    }
  }

  private get isUserLogged(): boolean {
    return this.$store.state.logged;
  }

  private checkIfChilean() {
    if (this.formData.nationality.value === 47) {
      this.chilean = true;
      this.formData.document_type = "1";
    } else {
      this.chilean = false;
    }
  }

  private async filterStreet(val: string, update: any, abort: any) {
    if (val == null) {
      return;
    }
    if (val === "") {
      update(() => {
        this.options_street = this.streets;
      });
      return;
    }
    update(() => {
      const value = val.toLowerCase();
      this.options_street = (this.streets as any).filter((street: any) => {
        return street.nombre.toLowerCase().indexOf(value) > -1;
      });
    });
  }

  private filterDistrict(val: string, update: any) {
    if (val === "") {
      update(() => {
        this.options_district = comunas;
      });
      return;
    }

    update(() => {
      const value = val.toLowerCase();
      this.options_district = (comunas as any).filter((comuna: any) => {
        return comuna.label.toLowerCase().indexOf(value) > -1;
      });
      if (this.formData.district) {
        this.options_district.unshift(this.formData.district);
      }
    });
  }

  private abortFilterFn() {
    this.options_district = comunas;
  }

  private filterNationality(val: string, update: any) {
    if (val === "") {
      update(() => {
        this.options_nationality = countries;
      });
      return;
    }

    update(() => {
      const value = val.toLowerCase();
      this.options_nationality = (countries as any).filter((country: any) => {
        let filtered = country.label.toLowerCase().indexOf(value) > -1;
        return filtered;
      });
      if (this.formData.nationality) {
        this.options_nationality.unshift(this.formData.nationality);
      }
    });
  }

  private streetDistrictData() {
    this.$axios
      .get("calles", {
        params: {
          filter: {
            where: { estado: 1 },
            fields: ["id", "id_comuna", "nombre", "estado"],
            order: "nombre ASC"
          }
        }
      })
      .then(response => {
        this.streets = response.data;
      })
      .catch(error => {
        this.$q.notify({
          message: "Ha ocurrido un error",
          color: "principal",
          position: "top",
          timeout: 5000
        });
      });

    this.$axios
      .get("comunas/default")
      .then(response => {
        this.comuna_municipalidad = response.data;
      })
      .catch(error => {
        this.$q.notify({
          message: "Ha ocurrido un error",
          color: "principal",
          position: "top",
          timeout: 5000
        });
      });
  }

  private getMotivo() {
    this.$axios
      .post("servicio-motivos/servicio", {
        id_servicio: Number(this.$store.state.ticket.service.id)
      })

      .then(response => {
        this.aMotivo = response.data;
        this.loadStoredData();
      })
      .catch(error => {
        this.$q.notify({
          message: this.$t("notification.problem") as string,
          color: "principal",
          position: "top",
          timeout: 5000
        });
      });
  }

  private mounted() {
    this.formatRut();
    this.highlightMotive();
    this.streetDistrictData();
    this.getMotivo();

    /*
    if (this.date_request == true) {
      this.$store.state.ticket.motive = "1";
      this.formData.motive = "1";
    }
    */
  }
}
